div.icon {
    padding: var(--spacing--xs);

    .icon--container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: calc(var(--spacing--lg) * 1.15);

        svg.svg--circle {
            width: 100%;
            max-height: 5rem;
            position: absolute;
            top: 0;
            fill: none;
            stroke: var(--color--third);
            stroke-width: 2;
            margin-inline: auto;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        i {
            font-style: normal;

            svg {
                width: 100%;
                max-width: var(--spacing--xl);
                max-height: var(--spacing--lg);
                fill: var(--color--primary);
            }

            p {
                font-size: calc(var(--headline--sm) * 1.25);
                line-height: var(--headline--sm--height);
                font-weight: 600;
                color: var(--color--primary);
                text-align: center;
            }
        }
    }

    .paragraph {
        text-align: center;
    }
}
