nav.navigation--header {
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--color--light);
    overflow: hidden;
    transition: all 0.35s var(--easing--out);


    @media (--media--2xl) {
        display: inline-block;
        height: initial;
        position: relative;
        top: initial;
        left: initial;
        right: initial;

        ul.navigation--list {
            padding: 0;

            li.navigation--listitem {
                display: inline;
                padding: 0 calc(var(--spacing--sm) * 1.25);

                &:first-child {
                    padding-left: 0;
                }

                a {
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                }
            }
        }
    }

    &.active {
        height: 100vh;
        overflow-y: scroll;
    }

    ul.navigation--list {
        grid-row-start: 1;
        padding-top: var(--spacing--lg);

        li.navigation--listitem {
            list-style-type: none;
            display: block;
            padding: var(--spacing--sm) 0;

            a {
                font-size: var(--headline--sm);
                line-height: var(--headline--sm-height);
                font-weight: 600;
                color: var(--color--font-base);

                @media (hover:hover) {
                    &:hover {
                        color: var(--color--secondary);
                    }
                }
            }

            &.current {
                a {
                    color: var(--color--primary);

                    @media (hover:hover) {
                        &:hover {
                            color: var(--color--primary);
                        }
                    }
                }
            }

        }
    }
}
