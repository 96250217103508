a.button--emergency {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    font-size: var(--text--xs);
    line-height: 1;
    font-weight: 600;
    color: var(--color--light);
    background: var(--color--primary);
    padding: calc(var(--spacing--xs) * 0.605) var(--spacing--sm);
    padding-right: var(--spacing--md);
    border-radius: var(--radius--lg);
    transition: background 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            background: var(--color--secondary);
        }
    }

    svg {
        height: 20px;
        margin-right: var(--spacing--xs);
        fill: var(--color--light);
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    div {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        white-space: nowrap;

        span {
            font-weight: 300;
            white-space: nowrap;
            margin-bottom: 0.25rem;
        }
    }
}
