/* poppins-300 - latin */
@font-face {
    font-style: normal;
    font-family: 'Poppins';
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-600 - latin */
  @font-face {
    font-style: normal;
    font-family: 'Poppins';
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  /* poppins-700 - latin */
  @font-face {
    font-style: normal;
    font-family: 'Poppins';
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
