.text--group {
    position: relative;
    overflow: unset;

    svg.bubble {
        display: none;
        width: var(--spacing--xl);
        height: auto;
        position: absolute;
        top: calc(var(--spacing--xl) * -1);
        left: 0;

        @media (--media--xl) {
            display: block;
            width: var(--spacing--2xl);
        }
    }

    &.single {
        svg.bubble {
            display: block !important;
        }

        div.text--group--item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1rem;
            grid-column-start: 3;
            grid-column-end: span 3;

            @media (--media--sm) {
                grid-template-columns: repeat(4, 1fr);
                grid-column-start: 3;
                grid-column-end: span 4;
            }
            @media (--media--md) {
                grid-template-columns: repeat(6, 1fr);
                grid-column-end: span 6;
            }
            @media (--media--xl) {
                grid-column-start: 4;
            }
            @media (--media--2xl) {
                grid-column-start: 5;
            }

            div.headline {
                grid-column-start: 1;
                grid-column-end: span 3;

                @media (--media--sm) {
                    grid-column-end: span 4;
                }

                @media (--media--md) {
                    grid-column-start: 1;
                    grid-column-end: span 6;
                }
            }

            div.paragraph, a.button {
                grid-column-start: 1;
                grid-column-end: span 3;

                @media (--media--sm) {
                    grid-column-end: span 4;
                }

                @media (--media--md) {
                    grid-column-start: 2;
                    grid-column-end: span 5;
                }
            }
        }
    }

    &.double {
        grid-row-gap: calc(var(--spacing--column) * 4);

        div.text--group--item {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 1rem;
            grid-column-end: span 3;
            align-self: start;

            @media (--media--sm) {
                grid-column-end: span 4;
            }

            &:nth-child(1) {
                grid-column-start: 2;

                @media (--media--xl) {
                    grid-column-start: 3;
                }
            }

            &:nth-child(2) {
                grid-column-start: 3;

                @media (--media--sm) {
                    grid-column-start: 4;
                }
                @media (--media--md) {
                    grid-column-start: 5;
                }
                @media (--media--lg) {
                    grid-column-start: 6;
                }
                @media (--media--xl) {
                    grid-column-start: 7;
                }
                @media (--media--2xl) {
                    grid-column-start: 9;
                }
            }

            div.headline {
                grid-column-start: 1;
                grid-column-end: span 4;
            }

            div.paragraph, a.button {
                grid-column-start: 1;
                grid-column-end: span 4;

                @media (--media--md) {
                    grid-column-start: 2;
                    grid-column-end: span 3;
                }
            }
        }
    }

    &.left {
        div.text--group--item {
            &:nth-child(1) {
                grid-row-start: 1;
            }
            &:nth-child(2) {
                grid-row-start: 2;

                @media (--media--2xl) {
                    grid-row-start: 1;
                    margin-top: var(--spacing--2xl);
                }
            }
        }
    }

    &.right {
        div.text--group--item {
            &:nth-child(1) {
                grid-row-start: 2;

                @media (--media--2xl) {
                    grid-row-start: 1;
                    margin-top: var(--spacing--2xl);
                }
            }
            &:nth-child(2) {
                grid-row-start: 1;
            }
        }
    }
}




