:root {
    /* colors */
    --color--primary: #FF5784;
    --color--secondary: #FFBCCE;
    --color--third-light: #FCECE4;
    --color--third-dark: #8D223F;
    --color--fourth: #80112F;
    --color--light: #fff;
    --color--dark: #444;
    --color--font-base: #444;

    /* typography */
    --font--base-family: Poppins, sans-serif;
    --font--headline-family: Poppins, sans-serif;
    --font--base-size: 18px;

    /* text-size*/
    /* xs */
    --text--xs: 0.875rem;
    --text--xs-height: 1.3;
    /* base */
    --text--base: 1rem;
    --text--base-height: 1.5;

    /* headline-size*/
    /* xs */
    --headline--xs: 1.25rem; /* 20px */
    --headline--xs-height: 1.5;
    /* sm */
    --headline--sm: 1.75rem; /* 28px */
    --headline--sm-height: 1.25;
    /* md */
    --headline--md: 2.1875rem; /* 35px* | 40px | 45px | 50px */
    --headline--md-height: 1.2;
    /* lg */
    --headline--lg: 2.5rem; /* 40px* | 50px | 60px | 70px */
    --headline--lg-height: 1.05;
    /* xl */
    --headline--xl: 3.125rem; /* 50px* | 65px | 80px | 100px */
    --headline--xl-height: 1.05;

    /* spacing */
    --spacing--xs: 0.95rem;
    --spacing--sm: 1.25rem;
    --spacing--md: 1.875rem;
    --spacing--lg: 3.125rem;
    --spacing--xl: 6.25rem;
    --spacing--2xl: 12.5rem;

    /* grid */
    --spacing--column: 1.25rem; /* 20px */

    /* radius */
    --radius--sm: 10px;
    --radius--lg: 50px;

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (--media--md) {
        /* headline-size*/
        /* xs */
        --headline--xs: 1.25rem; /* 20px */
        --headline--xs-height: 1.5;
        /* sm */
        --headline--sm: 1.75rem; /* 28px */
        --headline--sm-height: 1.25;
        /* md */
        --headline--md: 2.5rem; /* 35px | *40px | 45px | 50px */
        --headline--md-height: 1.2;
        /* lg */
        --headline--lg: 3.125rem; /* 35px | *50px | 60px | 70px */
        --headline--lg-height: 1.05;
        /* xl */
        --headline--xl: 4.05rem; /* 50px | *65px | 80px | 100px */
        --headline--xl-height: 1.05;
    }

    @media (--media--lg) {
        /* headline-size*/
        /* xs */
        --headline--xs: 1.25rem; /* 20px */
        --headline--xs-height: 1.5;
        /* sm */
        --headline--sm: 1.75rem; /* 28px */
        --headline--sm-height: 1.25;
        /* md */
        --headline--md: 2.8125rem; /* 35px | 40px | *45px | 50px */
        --headline--md-height: 1.2;
        /* lg */
        --headline--lg: 3.75rem; /* 35px | 50px | *60px | 70px */
        --headline--lg-height: 1.05;
        /* xl */
        --headline--xl: 5rem; /* 50px | 65px | *80px | 100px */
        --headline--xl-height: 1.05;
    }

    @media (--media--2xl) {
        /* headline-size*/
        /* xs */
        --headline--xs: 1.25rem; /* 20px */
        --headline--xs-height: 1.5;
        /* sm */
        --headline--sm: 1.75rem; /* 28px */
        --headline--sm-height: 1.25;
        /* md */
        --headline--md: 3.125rem; /* 35px | 40px | 45px | *50px */
        --headline--md-height: 1.2;
        /* lg */
        --headline--lg: 4.375rem; /* 35px | 50px | 60px | *70px */
        --headline--lg-height: 1.05;
        /* xl */
        --headline--xl: 6.25rem; /* 50px | 65px | 80px | *100px */
        --headline--xl-height: 1.05;
    }
}

/* breakpoints with custom media, because css variables is not supportet in media queries */
@custom-media --media--sm (min-width: 576px);
@custom-media --media--md (min-width: 768px);
@custom-media --media--lg (min-width: 992px);
@custom-media --media--xl (min-width: 1250px);
@custom-media --media--2xl (min-width: 1800px);
