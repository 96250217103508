footer.footer {
    svg.footer--background {
        display: block;
        transform: translateY(1px);
        fill: var(--color--third);
    }

    div.grid--container {
        grid-template-rows: 4;
        position: relative;
        background: var(--color--third);

        div.social--container {
            display: flex;
            grid-column-start: 2;
            grid-row-start: 1;
            align-items: center;
            margin-bottom: var(--spacing--md);

            a.social--icon {
                margin-right: var(--spacing--sm);

                @media (hover: hover) {
                    &:hover {
                        svg {
                            fill: var(--color--primary);
                        }
                    }
                }

                &::last-child {
                    margin-right: 0;
                }

                svg {
                    display: block;
                    width: 2rem;
                    transition: fill 0.35s var(--easing--in-out);
                    fill: var(--color--font-base);
                }
            }
        }

        div.footer--column1 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 2;
            z-index: 1;
            margin-bottom: var(--spacing--md);

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-end: span 4;
            }
            @media (--media--xl) {
                grid-column-end: span 3;
                margin-bottom: 0;
            }
            @media (--media--2xl) {
                grid-column-end: span 4;
            }

            a.footer--button {
                display: flex;
                align-items: center;
                width: fit-content;
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                font-weight: 600;
                color: var(--color--primary);
                padding: 0.25rem 0;
                transition: color 0.35s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--secondary);
                        svg {
                            fill: var(--color--secondary);
                        }
                    }
                }

                svg {
                    display: inline-block;
                    width: 1.15rem;
                    margin-right: calc(var(--spacing--xs) * 0.75);
                    transition: fill 0.35s var(--easing--in-out);
                    fill: var(--color--primary);
                }
            }
        }

        div.footer--column2 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 3;
            z-index: 1;
            margin-bottom: var(--spacing--xl);

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-end: span 4;
                margin-bottom: 0;
            }
            @media (--media--xl) {
                grid-column-start: 6;
                grid-column-end: span 3;
                grid-row-start: 2;
            }
            @media (--media--2xl) {
                grid-column-start: 7;
            }
        }

        div.footer--column3 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 4;
            z-index: 1;

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-start: 7;
                grid-column-end: span 3;
                grid-row-start: 2;
            }
            @media (--media--xl) {
                grid-column-start: 10;
                grid-column-end: span 2;
            }
            @media (--media--2xl) {
                grid-column-start: 12;
            }
        }

        svg.bubble {
            display: block;
            height: 30rem;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
        }
    }
}
