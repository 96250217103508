.text {
    position: relative;

    svg.bubble {
        display: block;
        width: calc(var(--spacing--2xl) * 2);
        height: auto;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translate(10%, -33%);
    }
}
