div.hero--banner {
    div.grid--container {
        position: relative;
        overflow: unset;

        figure.hero--image {
            grid-row-start: 1;
            grid-row-end: span 2;
            position: relative;
            z-index: -10;
            touch-action: manipulation;

            @media (--media--md) {
                grid-row-end: span 3;
            }
            @media (--media--xl) {
                grid-row-end: span 4;
            }
            @media (--media--2xl) {
                grid-row-end: span 5;
            }

            svg.clippath {
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                touch-action: manipulation;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
                object-fit: cover;
                clip-path: url(#hero--mask);
                touch-action: manipulation;
            }

            div.image--bubble {
                position: absolute;
                inset: 0;
                overflow: hidden;
                clip-path: url(#hero--mask);
                touch-action: manipulation;

                svg {
                    opacity: 0.75;
                    width: 75%;
                    height: auto;
                    position: absolute;
                    bottom: 0;
                }

                &::before {
                    content: '';
                    width: 50%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        div.headline {
            grid-row-start: 2;
            margin-top: var(--spacing--xl);
        }

        div.text--content {
            grid-row-start: 3;
        }

        svg.bubble {
            height: auto;
            position: absolute;
            z-index: -10;
        }
    }

    &.left--1, &.left--2 {
        figure.hero--image {
            grid-column-start: 1;
            grid-column-end: span 4;
            margin-right: var(--spacing--md);
            aspect-ratio: 1 / 1;

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-end: span 7;
                grid-row-end: span 3;
            }
            @media (--media--lg) {
                grid-column-end: span 6;
            }
            @media (--media--xl) {
                grid-column-end: span 7;
                grid-row-end: span 4;
            }
            @media (--media--2xl) {
                grid-column-end: span 8;
                grid-row-end: span 5;
            }

            div.image--bubble {
                &::before {
                    right: 0;
                    background-image: linear-gradient(to right, transparent, var(--color--third));
                }
            }
        }

        div.headline {
            grid-column-start: 3;
            grid-column-end: span 3;

            @media (--media--sm) {
                grid-column-start: 4;
                grid-column-end: span 4;
            }
            @media (--media--md) {
                grid-column-start: 5;
                grid-column-end: span 5;
                margin-top: calc(var(--spacing--2xl) * 1.25) !important;
            }
            @media (--media--xl) {
                grid-column-start: 6;
            }
            @media (--media--2xl) {
                grid-column-start: 7;
                grid-column-end: span 6;
            }
        }

        div.text--content {
            grid-column-start: 3;
            grid-column-end: span 3;

            @media (--media--sm) {
                grid-column-start: 4;
                grid-column-end: span 4;
            }
            @media (--media--md) {
                grid-column-start: 6;
            }
            @media (--media--xl) {
                grid-column-start: 7;
                grid-column-end: span 4;
            }
            @media (--media--2xl) {
                grid-column-start: 9;
                grid-column-end: span 4;
            }
        }

        div.group {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 4;

            @media (--media--sm) {
                grid-column-start: 4;
            }
            @media (--media--md) {
                grid-column-start: 5;
            }
            @media (--media--xl) {
                grid-column-start: 7;
            }
            @media (--media--2xl) {
                grid-column-start: 9;
            }

            div.group--inner {
                .icon {
                    --el--row-count: 2;

                    @media (--media--md) {
                        --el--row-count: 2;
                    }
                    @media (--media--lg) {
                        --el--row-count: 2;
                    }
                }
            }
        }

        div.bubble--container {
            width: calc(var(--spacing--xl) * 1.5);
            height: auto;
            position: absolute;
            top: calc(var(--spacing--xl) * 1.75);
            right: 0;
            z-index: -10;
            overflow-x: hidden;

            @media (--media--sm) {
                width: calc(var(--spacing--xl) * 1.25);
            }
            @media (--media--md) {
                width: calc(var(--spacing--2xl) * 1.25);
                top: var(--spacing--xl);
            }
            @media (--media--lg) {
                width: calc(var(--spacing--2xl) * 1.5);
            }
            @media (--media--xl) {
                width: calc(var(--spacing--2xl) * 1.75);
            }

            svg.bubble {
                display: block;
                position: relative;
                transform: translateX(35%);

                @media (--media--md) {
                    transform: translateX(30%);
                }
                @media (--media--lg) {
                    transform: translateX(25%);
                }
                @media (--media--xl) {
                    transform: translateX(20%);
                }
                @media (--media--2xl) {
                    transform: translateX(0);
                }
            }
        }

    }

    &.left--1 {

        div.image--bubble {
            svg {
                left: 0;
                transform: translate(-25%, 25%);
            }
        }
    }

    &.left--2 {
        div.image--bubble {
            svg {
                right: 0;
                transform: translate(15%, 25%) rotate(-60deg) ;
            }
        }
    }

    /* !!!Right */

    &.right--1, &.right--2 {
        figure.hero--image {
            grid-column-start: 3;
            grid-column-end: span 4;
            grid-row-start: 1;
            grid-row-end: span 2;
            aspect-ratio: 1.05 / 1;

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-start: 4;
                grid-column-end: span 7;
            }
            @media (--media--lg) {
                grid-column-start: 5;
                grid-column-end: span 6;
            }
            @media (--media--xl) {
                grid-column-start: 6;
                grid-column-end: span 7;
            }
            @media (--media--2xl) {
                grid-column-end: span 9;
            }

            div.image--bubble {
                &::before {
                    left: 0;
                    background-image: linear-gradient(to right, var(--color--third), transparent);
                }
            }
        }

        div.headline {
            grid-column-start: 2;

            @media (--media--sm) {
                grid-column-end: span 5;
            }
            @media (--media--md) {
                grid-column-end: span 7;
                margin-top: calc(var(--spacing--2xl) * 1.25) !important;
            }
            @media (--media--lg) {
                grid-column-end: span 6;
            }
            @media (--media--xl) {
                grid-column-end: span 7;
                margin-top: var(--spacing--xl) !important;
            }
            @media (--media--2xl) {
                grid-column-end: span 8;
            }
        }

        svg.bubble {
            width: var(--spacing--xl);
            top: 0;
            left: 0;

            @media (--media--sm) { width: calc(var(--spacing--xl) * 1.25); }
            @media (--media--md) { width: calc(var(--spacing--xl) * 1.5); }
            @media (--media--lg) { width: calc(var(--spacing--xl) * 1.75); }
            @media (--media--xl) { width: calc(var(--spacing--2xl) * 1.25); }
            @media (--media--2xl) { width: calc(var(--spacing--2xl) * 1.5); }
        }
    }

    &.right--1 {
        div.image--bubble {
            svg {
                right: 0;
                transform: translate(25%, 35%);
            }
        }

        div.headline {
            grid-column-end: span 4;
        }

        div.text--content {
            grid-column-start: 2;
            grid-column-end: span 4;

            @media (--media--sm) { grid-column-start: 3; }
        }
    }

    &.right--2 {
        div.image--bubble {
            svg {
                left: 0;
                transform: translate(-30%, 20%) rotate(150deg);
            }
        }

        div.headline {
            grid-column-end: span 3;
        }

        div.text--content {
            grid-column-start: 2;
            grid-column-end: span 3;

            @media (--media--sm) {
                grid-column-end: span 4;
            }
        }
    }
}
