div.quotation {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    position: relative;

    @media (--media--sm) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (--media--xl) {
        grid-template-columns: repeat(8, 1fr);
    }
    @media (--media--2xl) {
        grid-template-columns: repeat(10, 1fr);
    }

    div.headline {
        grid-column-start: 1;
        grid-column-end: span 4;
        grid-row-start: 2;
        z-index: 1;

        @media (--media--sm) {
            grid-column-end: span 6;
        }
        @media (--media--xl) {
            grid-row-start: 1;
        }
        @media (--media--2xl) {
            grid-column-end: span 7;
        }
    }

    div.text--content, div.person--content {
        grid-column-start: 1;
        grid-column-end: span 4;
        z-index: 1;

        @media (--media--sm) {
            grid-column-start: 2;
            grid-column-end: span 5;
        }
        @media (--media--xl) {
            grid-column-start: 2;
            grid-column-end: span 6;
        }
        @media (--media--2xl) {
            grid-column-start: 3;
        }

    }

    div.text--content {
        grid-row-start: 3;
    }

    div.person--content {
        grid-row-start: 4;
        font-weight: 600;

        @media (--media--xl) {
            margin-bottom: var(--spacing--lg);
        }
        @media (--media--2xl) {
            margin-bottom: var(--spacing--xl);
        }
    }

    svg.svg--marks {
        display: block;
        grid-column-start: 4;
        grid-column-end: span 1;
        grid-row-start: 1;
        width: 100%;
        z-index: 1;
        margin-bottom: var(--spacing--sm);
        justify-self: end;

        @media (--media--sm) {
            grid-column-start: 5;
            grid-column-end: span 2;
            width: 75%;
        }
        @media (--media--lg) {
            margin-bottom: var(--spacing--md);
        }
        @media (--media--xl) {
            grid-column-start: 7;
            margin-bottom: 0;
        }
        @media (--media--2xl) {
            grid-column-start: 9;
            width: 100%;
        }
    }

    svg.svg--bubble {
        display: block;
        width: 30rem;
        position: absolute;
        top: var(--spacing--sm);
        left: 50%;
        z-index: 0;
        transform: translateX(-15%);
    }
}
