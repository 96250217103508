body.color--theme-dark {
    --color--third: var(--color--third-dark);
    --color--font-base: var(--color--light);

    /* body */
    background: var(--color--fourth);

    header {
        background: var(--color--fourth) !important;

        .navigation--header {
            background: var(--color--fourth) !important;
        }
    }

    form {
        div.selectContainer {
            div.currentOption, div.selectContainerInner {
                background: var(--color--primary) !important;

                div.selectOption {
                    &:hover {
                        background: var(--color--dark) !important;
                    }
                }
            }
        }
        input, textarea {
            color: var(--color--dark) !important;
            background: var(--color--light) !important;

            &:focus {
                border-color: var(--color--primary) !important;
            }
        }
    }

    svg.texttile--svg--negative, svg.textmedia--svg--negative {
        fill: var(--color--fourth);
    }
}
