div.group {

    &.multi--group {
        div.group--inner {
            --gap: calc(var(--spacing--column));
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: var(--gap);

            .icon, .tile {
                --gap-count: calc(var(--el--row-count) - 1);
                width: calc(100% / var(--el--row-count) - (var(--gap-count) / var(--el--row-count) * var(--gap)));
            }

            .icon {
                --el--row-count: 2;

                @media (--media--md) {
                    --el--row-count: 3;
                }
                @media (--media--xl) {
                    --el--row-count: 4;
                }
                @media (--media--2xl) {
                    --el--row-count: 6;
                }
            }

            .tile {
                --el--row-count: 1;

                @media (--media--md) {
                    --el--row-count: 1.5;
                }
                @media (--media--xl) {
                    --el--row-count: 2;
                }
                @media (--media--2xl) {
                    --el--row-count: 3;
                }
            }
        }
    }

    &.icon--group {
        div.group--inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: var(--spacing--column);

            .icon {
                --el--row-count: 2;
                --gap-count: calc(var(--el--row-count) - 1);

                width: calc(100% / var(--el--row-count) - (var(--gap-count) / var(--el--row-count) * var(--spacing--column)));

                @media (--media--md) {
                    --el--row-count: 2;
                }
                @media (--media--xl) {
                    --el--row-count: 3;
                }
                @media (--media--2xl) {
                    --el--row-count: 4;
                }
            }
        }
    }

    &.tile--group {
        div.group--inner {
            display: grid;
            grid-template-columns: 1;
            gap: var(--spacing--column);

            @media (--media--md) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (--media--xl) {
                grid-template-columns: repeat(3, 1fr);
            }

            .column {
                display: flex;
                flex-direction: column;
                gap: var(--spacing--column);

                &:nth-child(2) {
                    margin-top: var(--spacing--xl);
                }
                &:nth-child(3) {
                    margin-top: var(--spacing--lg);
                }
            }
        }
    }
}

