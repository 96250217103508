nav.navigation--footer {

    ul.navigation--list {

        li.navigation--listitem {
            list-style-type: none;
            display: block;
            padding: calc(var(--spacing--xs) * 0.35) 0;

            a {
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                color: var(--color--font-base);

                @media (hover:hover) {
                    &:hover {
                        color: var(--color--primary);
                    }
                }
            }

            &.current {
                a {
                    font-weight: 600;
                    color: var(--color--primary);

                    @media (hover:hover) {
                        &:hover {
                            color: var(--color--primary);
                        }
                    }
                }
            }
        }
    }
}
