.gdpr-cookieconsent-container {
    --cookieconsent-theme-color: var(--color--primary);
    --cookieconsent-overlay-color: rgb(0 0 0 / .5);
    --cookieconsent-bg-color: var(--color--light);

    --cookieconsent-group-bg-color: var(--color--light);

    --cookieconsent-button-text-color: var(--color--light);
    --cookieconsent-button-primary-color: var(--cookieconsent-theme-color);
    --cookieconsent-button-secondary-color: rgb(255 255 255);
    --cookieconsent-button-primary-text-color: var(--cookieconsent-button-text-color);
    --cookieconsent-button-secondary-text-color: var(--color--dark);
    --cookieconsent-button-borderradius: 100px;

    --cookieconsent-spacing: 1rem;
    --cookieconsent-width: 600px;

    --cookieconsent-focus-outline: transparent;
    --cookieconsent-focus-outline-offset: 0;

	flex-direction: row !important;
	justify-content: center;
    background: rgb(0 0 0 / .5);
    overflow-y: scroll;

    &::before {
        display: none;
    }

    &:focus-visible {
        outline: transparent;
    }

	.gdpr-cookieconsent-settings {
		position: absolute;
		top: var(--spacing--xl);
		left: 50%;
        color: var(--color--dark);
        border-radius: var(--radius--sm);
		transform: translateX(-50%);

        @media (max-width: 500px) {
            width: 90%;
        }

		.hidden {
			display: none !important;
		}

		.gdpr-cookieconsent-settings__content {
			display: flex;
            flex-direction: column;
            padding: calc(var(--spacing--md) * 0.75);
            padding-top: var(--spacing--md);

            @media (--media--sm) {
                padding: calc(var(--spacing--lg) * 0.75);
                padding-top: var(--spacing--lg);
            }

			.gdpr-cookieconsent-settings__content__info {
                display: block;
                order: 1;
				font-family: var(--font--base-family);
				hyphens: auto;
                color: var(--color--dark);
				padding-bottom: var(--spacing--md);

				h2 {
					font-size: var(--headline--md);
                    text-align: center;
				}

				.gdpr-cookieconsent-settings__content__info__description {
					display: block;
					font-size: var(--text--base);
					line-height: var(--text--base-height);
                    margin-bottom: var(--spacing--xs);

					p {
                        hyphens: auto;
                        text-align: center;
					}

                    &.gdpr-cookieconsent-settings__content__info__description--settings {
                        display: none;
                    }
				}
			}

            .gdpr-cookieconsent-settings__content__settings {
                order: 2;
                margin-top: 0;
                margin-bottom: var(--spacing--sm);

                .gdpr-cookieconsent-switch--group {
                    font-size: var(--headline--xs);

                    .gdpr-cookieconsent-switch--cookie {
                        font-size: var(--text--base);
                    }
                }
            }

			.gdpr-cookieconsent-settings__content__cta {
                align-items: center;
                order: 3;
				margin: 0 !important;

				.gdpr-cc-button {
                    width: fit-content;
					font-size: var(--text--base);
                    font-weight: 600;
                    padding: var(--spacing--xs) var(--spacing--md);
                    transition: background 0.35s var(--easing--in-out);

                    @media (max-width: 500px) {
                        white-space: normal;
                    }

                    &:hover {
                        background: var(--color--secondary);
                        filter: brightness(1);
                    }
				}

                #gdpr-cc-btn-accept {
                    margin-bottom: var(--spacing--xs);
                }

                .gdpr-cc-button--secondary, #gdpr-cc-btn-accept-necessary {
                    color: var(--color--dark);
                    background: none !important;
                    border: none;
                    transition: color 0.35s var(--easing--in-out);

                    &:hover {
                        color: var(--color--secondary);
                    }
                }
			}

            .gdpr-cookieconsent-settings__content__links {
                order: 4;
                color: var(--color--dark);
                margin-top: var(--spacing--md);
				text-align: center;

                span {
                    font-weight: 300;
                }

                a {
                    font-weight: 400;
                    transition: color 0.35s var(--easing--in-out);

                    &:hover {
                        color: var(--color--secondary);
                    }
                }
			}
		}
	}
}

.gdpr-cookieconsent-switch {
    --cookieconsent-switch-thumb-size: 1.25rem;
    --cookieconsent-switch-thumb-color: rgb(255 255 255);
    --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25);
    --cookieconsent-switch-length: calc(var(--cookieconsent-switch-thumb-size) * 2);
    --cookieconsent-switch-padding: 4px;
    --cookieconsent-switch-inactive-color: var(color--grey);
    --cookieconsent-switch-active-color: var(--cookieconsent-theme-color);
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(--cookieconsent-focus-outline-offset);
}
