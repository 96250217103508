div.textmedia {
    align-content: center;
    position: relative;
    overflow: unset;
    isolation: isolate;

    svg.textmedia--svg--negative {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;

        &.position--bottom {
            bottom: 0;
        }
        &.position--top {
            top: 0;
        }
    }

    div.text {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1rem;
        grid-column-start: 2;
        grid-column-end: span 4;
        grid-row-start: 2;
        align-content: center;
        z-index: 1;
        margin-bottom: var(--spacing--xl);

        @media (--media--sm) {
            grid-template-columns: repeat(6, 1fr);
            grid-column-end: span 6;
        }
        @media (--media--md) {
            grid-template-columns: repeat(7, 1fr);
            grid-column-start: 3;
            grid-column-end: span 7;
        }
        @media (--media--lg) {
            grid-template-columns: repeat(6, 1fr);
            grid-column-start: 4;
            grid-column-end: span 6;
            grid-row-end: span 2;
            margin-top: var(--spacing--xl);
        }
        @media (--media--xl) {
            grid-column-start: 6;
            grid-column-end: span 6;
            grid-row-start: 1;
            grid-row-end: span 1;
            margin-top: calc(var(--spacing--xl) + var(--spacing--lg));
        }
        @media (--media--2xl) {
            grid-column-start: 8;
        }

        div.headline {
            grid-column-start: 1;
            grid-column-end: span 4;

            @media (--media--sm) {
                grid-column-end: span 4;
            }
            @media (--media--md) {
                grid-column-end: span 6;
            }
        }

        div.paragraph, a.button {
            grid-column-start: 1;
            grid-column-end: span 4;

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-start: 2;
            }
            @media (--media--lg) {
                grid-column-end: span 5;
            }
            @media (--media--xl) {
                grid-column-start: 3;
                grid-column-end: span 4;
            }
        }
    }

    figure.textmedia--image {
        grid-column-start: 1;
        grid-column-end: span 5;
        grid-row-start: 1;
        position: relative;
        margin-bottom: var(--spacing--md);
        margin-left: calc(var(--spacing--lg) * -1);
        touch-action: manipulation;
        aspect-ratio: 1 / 0.95;

        @media (--media--sm) {
            grid-column-end: span 7;
        }
        @media (--media--md) {
            grid-column-end: span 8;
        }
        @media (--media--lg) {
            grid-row-end: span 2;
            margin-bottom: var(--spacing--2xl);
        }
        @media (--media--xl) {
            grid-column-end: span 7;
            grid-row-end: span 1;
            margin-bottom: var(--spacing--md);
        }
        @media (--media--2xl) {
            grid-column-end: span 9;
            margin-right: var(--spacing--md);
            margin-left: var(--spacing--xl);
        }

        svg.clippath {
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            touch-action: manipulation;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            object-fit: cover;
            clip-path: url(#mask);
            touch-action: manipulation;
        }

        div.image--bubble {
            position: absolute;
            inset: 0;
            overflow: hidden;
            clip-path: url(#mask);
            touch-action: manipulation;

            @media (--media--xl) {
                &::before {
                    content: '';
                    width: 100%;
                    position: absolute;
                    inset: 0;
                    background-image: linear-gradient(to right, transparent, var(--color--third));
                }
            }

            svg {
                width: 100%;
                height: auto;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    &.mirrored {
        overflow-x: hidden;

        div.text {
            @media (--media--md) {
                grid-column-start: 2;
            }

            @media (--media--md) {
                div.paragraph, a.button {
                    grid-column-start: 2;
                    grid-column-end: span 5;
                }
            }
        }

        figure.textmedia--image {
            grid-column-start: 2;
            margin-left: 0;
            transform: translateX(var(--spacing--lg));
            aspect-ratio: 1 / 1;

            @media (--media--xl) {
                grid-column-start: 6;
            }
            @media (--media--2xl) {
                grid-column-start: 7;
                margin-right: var(--spacing--xl);
                margin-left: var(--spacing--md);
                transform: translateX(0);
            }

            img {
                clip-path: url(#mask-mirror);
            }

            div.image--bubble {
                clip-path: url(#mask-mirror);

                @media (--media--xl) {
                    &::before {
                        background-image: linear-gradient(to left, transparent, var(--color--light));
                    }
                }
            }
        }

        svg.bubble {
            display: none;

            @media (--media--xl) {
                display: block;
                width: calc(var(--spacing--2xl) * 1.25);
                height: auto;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}


