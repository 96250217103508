div.paragraph {
    hyphens: auto;
    word-break: break-word;

    p {
        strong {
            font-weight: 600;
        }
    }

    ul {
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(var(--spacing--xs) * 1.25);

            &::before {
                content: '–';
                position: absolute;
                left: 0;
                font-weight: 500;
            }
        }
    }

    a[href^="mailto"], a[href^="tel"] {
        display: block;
        width: fit-content;
        position: relative;
        font-weight: 600;
        padding: 0.5rem 0;
        padding-left: 1.75rem;
        transition: all 0.35s var(--easing--in-out);

        @media (hover: hover) {
            &:hover {

                padding-left: 2rem;

                &::before {
                    transform: translateY(-50%) scale(1.5);
                }
            }
        }

        &::before {
            width: 1.15rem;
            position: absolute;
            top: calc(var(--text--base) + 0.25rem);
            left: 0;
            transform: translateY(-50%) scale(1);
            transition: all 0.35s var(--easing--in-out);
        }
    }

    a[href^="mailto"] {
        &::before {
            content: url(../Images/mail.svg);
        }
    }

    a[href^="tel"] {
        &::before {
            content: url(../Images/phone.svg);
        }
    }

    &.fc--font-base {
        a[href^="mailto"], a[href^="tel"] {
            &::before {
                filter: invert(21%) sepia(13%) saturate(15%) hue-rotate(4deg) brightness(96%) contrast(80%);
            }
        }
    }
    &.fc--light {
        a[href^="mailto"], a[href^="tel"] {
            &::before {
                filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
            }
        }
    }
}
