a.button {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: var(--text--base);
    line-height: 1;
    font-weight: 600;
    padding: var(--spacing--xs) var(--spacing--sm);
    border-radius: var(--radius--lg);
    transition: margin 0.5s var(--easing--out);

    @media (hover: hover) {
        &:hover {
            span {
                margin: 0 var(--spacing--xs);
            }
        }
    }

    span {
        display: inline-block;
        width: 100%;
        hyphens: auto;
        word-break: break-word;
        margin: 0;
        transition: margin 0.75s var(--easing--in-out);
    }

    svg {
        height: 20px;
        padding-left: var(--spacing--xs);
        transition: stroke 0.5s var(--easing--out);
        fill: none;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    &.bg--primary {
        color: var(--color--light);

        svg {
            stroke: var(--color--light);
        }
    }

    &.bg--light {
        color: var(--color--dark);

        svg {
            stroke: var(--color--dark);
        }
    }
}
