header.header {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: var(--color--light);
    padding: var(--spacing--xs) 0;
    overflow: unset;

    div.header--inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            display: inline-block;

            a {
                display: flex;
                align-items: center;

                svg {
                    display: block;
                    width: 3.5rem;
                }

                span {
                    display: none;

                    @media (--media--md) {
                        display: inline-block;
                        font-size: var(--text--base);
                        line-height: var(--text--xs-height);
                        font-weight: 600;
                        margin-left: var(--spacing--xs);
                    }
                }
            }
        }

        .navigation--content {
            display: flex;
            align-items: center;

            .button--emergency {
                margin-right: var(--spacing--md);

                @media (--media--2xl) {
                    margin-right: 0;
                    margin-left: var(--spacing--md);
                }


                @media (max-width: 600px) {
                    padding: var(--spacing--xs);

                    svg {
                        margin-right: 0;
                    }

                    div {
                        display: none;
                    }
                }
            }
        }
    }
}
