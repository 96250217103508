body.color--theme-light {
    --color--third: var(--color--third-light);

    svg.bubble {
        opacity: 0.5;
    }

    svg.texttile--svg--negative, svg.textmedia--svg--negative {
        fill: var(--color--light);
    }
}
