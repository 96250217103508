div.form {
    grid-template-rows: 2;
    position: relative;

    div.headline {
        grid-column-start: 2;
        grid-column-end: span 4;
        grid-row-start: 1;

        @media (--media--xl) {
            grid-column-start: 3;
            grid-column-end: span 5;
        }
    }

    div.paragraph {
        grid-column-start: small;
        grid-column-end: span 3;
        grid-row-start: 2;
    }

    svg.bubble {
        display: block;
        width: var(--spacing--xl);
        height: auto;
        position: absolute;
        top: calc(var(--spacing--xl) * -1);
        right: 0;
        z-index: -10;

        @media (--media--sm) { width: calc(var(--spacing--xl) * 1.25); }
        @media (--media--md) { width: calc(var(--spacing--xl) * 1.5); }
        @media (--media--lg) { width: calc(var(--spacing--xl) * 1.75); }
        @media (--media--xl) { width: calc(var(--spacing--2xl) * 1.25); }
        @media (--media--2xl) { width: calc(var(--spacing--2xl) * 1.5); }
    }

    form {
        grid-area: small;
        grid-row: 3;

        fieldset {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
            border: none;
            gap: var(--spacing--column);

            h2 {
                width: 100%;
                padding-top: var(--spacing--md);
            }

            .clearfix {
                display: inline-block;
                width: 100%;

                @media (--media--sm) {

                    &.w60 {
                        width: calc(60% - var(--spacing--column) * 0.5);
                    }
                    &.w50 {
                        width: calc(50% - var(--spacing--column) * 0.5);
                    }
                    &.w40 {
                        width: calc(40% - var(--spacing--column) * 0.5);
                    }
                }

                label {
                    display: none;
                }

                input,
                textarea {
                    display: block;
                    width: 100%;
                    font-family: var(--font--base-family);
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    color: var(--color--font-base);
                    background: var(--color--third);
                    outline: none;
                    padding: var(--spacing--xs) var(--spacing--sm);
                    border: 2px solid transparent;
                    border-radius: 30px;

                    &::placeholder {
                        opacity: 1;
                        color: rgba(68, 68, 68, 0.4);
                    }

                    &:-ms-input-placeholder {
                        color: rgba(68, 68, 68, 0.4);
                    }

                    &::-ms-input-placeholder {
                        color: rgba(68, 68, 68, 0.4);
                    }

                    &:focus {
                        border-color: var(--color--dark);
                    }
                    &.add-on {width: auto;}
                }

                input[type="file"] {
                    position: relative;
                    color: var(--color--primary);
                    padding-left: calc(var(--spacing--md) * 2);
                    border: none;
                    cursor: pointer;
                    transition: all 0.35s var(--easing--in-out);

                    &::-webkit-file-upload-button {
                        width: 0;
                        height: 0;
                        visibility: hidden;
                        padding: 0;
                        margin: 0;
                        border: none;
                    }

                    &::before {
                        content: url(../Images/upload.svg);
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 50%;
                        left: var(--spacing--sm);
                        filter: invert(29%) sepia(0%) saturate(3902%) hue-rotate(321deg) brightness(90%) contrast(98%);
                        transform: translateY(-50%) rotate(0deg);
                        transition: all 0.35s var(--easing--in-out);
                    }

                }

                textarea {
                    height: 250px;
                    resize: none;
                }

                .selectContainer {
                    position: relative;

                    .currentOption {
                        position: relative;
                        font-size: var(--text--base);
                        line-height: var(--text--base-height);
                        color: var(--color--light);
                        background: var(--color--dark);
                        padding: var(--spacing--xs) var(--spacing--sm);
                        border: 2px solid rgba(256, 256, 256, 0);
                        border-radius: 30px;
                        cursor: pointer;
                        transition: all 0.35s var(--easing--in-out);

                        &::after {
                            content: url(../Images/arrow-down.svg);
                            width: 20px;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            right: var(--spacing--sm);
                            filter: invert(100%) sepia(0%) saturate(4815%) hue-rotate(323deg) brightness(112%) contrast(100%);
                            transform: translateY(-50%) rotate(0deg);
                            transition: transform 0.35s var(--easing--in-out);
                        }
                    }

                    .selectContainerInner {
                        opacity: 0;
                        width: 100%;
                        position: absolute;
                        top: 105%;
                        left: -9999px;
                        z-index: 110;
                        background: var(--color--dark);
                        overflow: hidden;
                        border-radius: 30px;
                        transition: opacity 0.35s var(--easing--in-out), left 0.01s 0.375s;

                        .selectOption {
                            font-size: var(--text--base);
                            line-height: var(--text--base-height);
                            color: var(--color--light);
                            padding: 0.75rem var(--spacing--sm);
                            cursor: pointer;
                            transition: background 0.35s var(--easing--in-out);

                            &:hover {
                                background: var(--color--primary);
                            }

                            &:first-child {
                                padding-top: var(--spacing--sm);
                            }
                            &:last-child {
                                padding-bottom: var(--spacing--sm);
                            }
                        }
                    }

                    &.active {
                        .currentOption {
                            &::after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }

                        .selectContainerInner {
                            opacity: 1;
                            left: 0;
                            transition: left 0.01s, opacity 0.35s 0.1s var(--easing--in-out);
                        }
                    }
                }

                &.checkbox-input {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding-top: var(--spacing--sm);

                    label {
                        display: block;
                        order: 2;
                        font-size: 1.05rem;
                        line-height: 1.5;
                        color: var(--color--font-base);
                        padding-left: 5px;
                        margin-bottom: 0.25rem;
                    }

                    input[type="checkbox"] {
                        position: relative;
                        padding: 0;
                        margin-right: 0.5rem;
                        cursor: pointer;

                        &::before {
                            content: '';
                            display: block;
                            width: 15px;
                            height: 15px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: 5;
                            background: var(--color--light);
                            border: 1px solid var(--color--primary);
                            transform: translate(-50%, -50%);
                        }

                        &:checked {
                            &::before {
                                background: var(--color--primary);
                            }
                        }
                    }
                }

                &.error {
                    .help-inline {
                        display: none;
                    }
                    .currentOption, input, textarea {
                        color: rgb(156, 10, 7);
                        border: 2px solid rgb(156, 10, 7);
                    }
                }
            }
        }

        nav.form-navigation {

            ul {
                padding-top: calc(var(--spacing--md) * 1.75);

                li.submit {
                    list-style: none;

                    button {
                        display: block;
                        position: relative;
                        font-size: var(--text--base);
                        line-height: 1;
                        font-weight: 600;
                        color: var(--color--light);
                        background: var(--color--primary);
                        padding: var(--spacing--xs) var(--spacing--md);
                        margin: 0 auto;
                        border: none;
                        border-radius: var(--radius--lg);
                        cursor: pointer;
                        transition: padding 0.5s var(--easing--in-out);

                        @media (hover: hover) {
                            &:hover {
                                padding: var(--spacing--xs) var(--spacing--lg);
                            }
                        }
                    }
                }
            }
        }
    }
}
