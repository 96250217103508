.texttile--container {
    position: relative;
    background: var(--color--third);
    overflow-x: unset;
    isolation: isolate;

    svg.texttile--svg--negative {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;

        &.position--bottom {
            bottom: 0;
        }
        &.position--top {
            top: 0;
        }
    }

    div.headline--main {
        margin-top: var(--spacing--md);

        @media (--media--md) {
            margin-top: var(--spacing--lg);
        }

        @media (--media--2xl) {
            margin-top: var(--spacing--xl);
        }
    }

    .tile--container--inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
    }

    &.text--left {
        div.headline--main {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 1;

            @media (--media--sm) {
                grid-column-end: span 5;
            }
            @media (--media--md) {
                grid-column-end: span 7;
            }
            @media (--media--lg) {
                grid-column-end: span 6;
            }
            @media (--media--2xl) {
                grid-column-end: span 7;
            }
        }

        div.text--container {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 2;

            @media (--media--sm) {
                grid-column-end: span 5;
            }
            @media (--media--md) {
                grid-column-end: span 4;
                padding-right: var(--spacing--md);
            }

            @media (--media--xl) {
                grid-column-end: span 6;
            }
            @media (--media--2xl) {
                grid-column-start: 2;
                grid-column-end: span 4;
            }
        }

        div.tile--container--1 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 3;
            margin-top: var(--spacing--xl);

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-end: span 4;
            }
            @media (--media--xl) {
                grid-column-start: 4;
            }
            @media (--media--2xl) {
                grid-column-start: 6;
                grid-row-start: 2;
                grid-row-end: span 2;
                margin-top: var(--spacing--lg);
            }
        }

        div.tile--container--2 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 4;
            margin-top: 1rem;

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-start: 6;
                grid-column-end: span 4;
                grid-row-start: 2;
                grid-row-end: span 2;
                margin-top: var(--spacing--lg);
            }
            @media (--media--xl) {
                grid-column-start: 8;
                grid-row-end: span 4;
                margin-top: 0;
            }
            @media (--media--2xl) {
                grid-column-start: 10;
                grid-row-start: 1;
                grid-row-end: span 3;
            }
        }
    }
    &.text--right {
        div.headline--main {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 1;

            @media (--media--sm) {
                grid-column-end: span 5;
            }
            @media (--media--md) {
                grid-column-start: 3;
                grid-column-end: span 7;
            }
            @media (--media--lg) {
                grid-column-start: 4;
                grid-column-end: span 6;
            }
            @media (--media-xl) {
                grid-column-start: 5;
                grid-column-end: span 7;
            }
            @media (--media--2xl) {
                grid-column-start: 8;
                grid-column-end: span 6;
            }
        }

        div.text--container {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 2;

            @media (--media--sm) {
                grid-column-end: span 5;
            }
            @media (--media--md) {
                grid-column-start: 6;
                grid-column-end: span 4;
                padding-left: var(--spacing--md);
            }

            @media (--media--xl) {
                grid-column-start: 6;
                grid-column-end: span 6;
            }
            @media (--media--2xl) {
                grid-column-start: 10;
                grid-column-end: span 4;
            }
        }

        div.tile--container--1 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 3;
            margin-top: var(--spacing--xl);

            @media (--media--sm) {
                grid-column-end: span 6;
            }
            @media (--media--md) {
                grid-column-end: span 4;
                grid-row-start: 2;
                grid-row-end: span 2;
                margin-top: var(--spacing--lg);
            }
            @media (--media--2xl) {
                grid-column-start: 2;
                grid-row-start: 1;
                grid-row-end: span 3;
                margin-top: 0;
            }
        }

        div.tile--container--2 {
            grid-column-start: 2;
            grid-column-end: span 4;
            grid-row-start: 4;
            margin-top: 1rem;

            @media (--media--sm) {
                grid-column-end: span 6;
                grid-row-start: 4;
            }
            @media (--media--md) {
                grid-column-start: 6;
                grid-column-end: span 4;
                grid-row-start: 3;
                margin-top: var(--spacing--xl);
            }
            @media (--media--2xl) {
                grid-column-start: 6;
                grid-row-start: 2;
                grid-row-end: span 2;
                margin-top: var(--spacing--lg);
            }
        }
    }
}
