.grid--container {
    display: grid;
    grid-template-columns:  0.125fr
                                [large-start medium-start small-start]
                                    repeat(4, 1fr)
                                [large-end medium-end small-end]
                            0.125fr;
    grid-column-gap: 1rem;
    overflow-x: hidden;

    @media (--media--sm) {
        grid-template-columns:  0.5fr
                                    [large-start medium-start small-start]
                                        repeat(6, 1fr)
                                    [large-end medium-end small-end]
                                0.5fr;
    }

    @media (--media--md) {
        grid-template-columns:  0.75fr
                                    [large-start]
                                        1fr
                                        [medium-start small-start]
                                            repeat(6, 1fr)
                                        [medium-end small-end]
                                        1fr
                                    [large-end]
                                0.75fr;
    }

    @media (--media--lg) {
        grid-template-columns:  1.5fr
                                    [large-start]
                                        1fr
                                        [medium-start small-start]
                                            repeat(6, 1fr)
                                        [medium-end small-end]
                                        1fr
                                    [large-end]
                                1.5fr;
    }

    @media (--media--xl) {
        grid-template-columns:  2.5fr
                                    [large-start]
                                        1fr
                                        [medium-start]
                                            1fr
                                            [small-start]
                                                repeat(6, 1fr)
                                            [small-end]
                                            1fr
                                        [medium-end]
                                        1fr
                                    [large-end]
                                2.5fr;
        grid-column-gap: 1rem;
    }

    @media (--media--2xl) {
        grid-template-columns:  3.5fr
                                    [large-start]
                                        1fr
                                        [medium-start]
                                            1fr
                                            [small-start]
                                                repeat(8, 1fr)
                                            [small-end]
                                            1fr
                                        [medium-end]
                                        1fr
                                    [large-end]
                                3.5fr;
    }

    .grid-area-\[large\] {
        grid-area: large;
    }
    .grid-area-\[medium\] {
        grid-area: medium;
    }
    .grid-area-\[small\] {
        grid-area: small;
    }
}
